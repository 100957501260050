import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components';
import { buttons, breakpoint } from '../components/styles'

import rxFaceShield from '../imgs/covid-sales/D6Covid19Shield.png'
import d6FilterMask from '../imgs/covid-sales/D6FilterMask.jpg'
import d6FilterMaskCoomingSoon from '../imgs/covid-sales/D6FilterMask.png'
import box from '../imgs/covid-sales/BoxIllustration.png'
import head from '../imgs/covid-sales/HeadIllustration.png'
import madeInUSA from '../imgs/covid-sales/MadeInUSA.png'
import rxFaceShieldReplacement from '../imgs/covid-sales/D6CovidShieldReplacement.png'

const Products = styled.div`
  display: block;
  max-width: 1200px;
  margin: 30px auto;
  padding: 0 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 20px 10px;
    &.bullet {
      list-style: disc;
    }
  }

  .product {
    display: block;

    .title-section {
      h1 {
        font-weight: 600;
      }
      h2 {
        font-weight: 400;
      }
      h1, h2 {
        margin: 0;
      }
    }

    .donation-header {
      text-align: center;
      font-size: 1.5em;
      font-weight: 500;
      background: #8bc63f;
      color: #fff;
      margin: 10px 0;
      padding: 10px 0;
    }

    .listing {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      height: auto;
      margin: 50px 0;
    
      .image {
        width: 60%;
        align-self: flex-start;
        overflow: hidden;
        img {
          max-width: 100%;
        }
      }
      .info {
        width: 40%;
        display: flex;
        flex-flow: column;
        .actions {
          display: flex;
          flex-flow: column;
          margin: 60px auto auto;
          .price {
            font-size: 40px;
            font-weight: 600;
          }
        }
        .details {
          display: flex;
          flex-flow: column;
          margin: auto auto 60px;
        }
      }
    }

    .specs {
      display: flex;
      flex-flow: row wrap;
      .column {
        max-width: 300px;
        margin: 30px;
        .title {
          font-weight: 600;
          border-bottom: 1px solid #000;
          width: 100%;
          display: block;
        }
      }
      .callout {
        max-width: 900px;
        margin: 40px auto 0;
        text-align: center;
        font-weight: 400;
      }
    }

    .bottom-actions {
      justify-content: space-between;
      align-content: center;
      display: flex;
      max-width: 1000px;
      margin: auto;
      a,
      button {
        width: 25%;
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .additional-images {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      .column {
        width: 50%;
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: center;
        max-width: 300px;
        margin: 30px;
        min-width: 200px;
        border-bottom: 1px solid #666;
        .image {
          padding: 100% 100% 0 0;
          background-color: #666;
          background-size: 80%;
          background-position: center;
          background-repeat: no-repeat;
        }
        .info {
          width: 100%;
        }
      }
    }
  }

  .email {
    display: block;
    margin: 40px auto;
    text-align: center;
    font-weight: 300;
    a {
      font-weight: 500;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .usa-made {
    display: block;
    margin: 40px auto;
    width: 40%;
    max-width: 150px;
    img {
      max-width: 100%;
    }
  }

  @media screen and (max-width: ${breakpoint.medium}){
    .product {
      .specs .column {
        margin: 15px;
      }
    }
  }

  @media screen and (max-width: ${breakpoint.small}){
    .product {
      .listing {
        margin-bottom: 25px;
        .image, .info {
          width: 100%;
        }
        .info {
          .actions {
            margin: 15px 0;
          }
          .details {
            margin: 0;
          }
        }
      }
      .specs {
        .column {
          max-width: 100%;
        }
        .callout {
          margin-top: 0;
        }
      }
      .bottom-actions {
        flex-flow: column;
        width: 100%;
        button {
          width: 100%;
        }
      }
      .additional-images {
        .column {
          width: 100%;
        }
      }
      .usa-made {
        margin: 20px auto 60px;
      }
    }
  }
`;

const Button = styled(buttons.default)`
  margin: 5px 0;
`;

const LinkButton = styled(buttons.external.primary)`
  margin: 5px 0;
`;

export default class CovidSales extends React.Component {

  donationHeader() {
    return (
      <div className='donation-header'>For every five masks purchased from this website, D6 Inc. will donate one mask to communities in need.</div>
    )
  }

  rxFaceShieldActionButtons() {
    return (
      <React.Fragment>
        <Button
          className='snipcart-add-item'
          data-item-id='1'
          data-item-name='RX Safety Face Shield'
          data-item-price='12.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='227'
          data-item-description='Maintain superior protection with our Safety Face Shield'>Buy Now</Button>
          <Button
          className='snipcart-add-item'
          data-item-id='3'
          data-item-name='RX Safety Face Shield - 25 Pack'
          data-item-price='300.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='2722'
          data-item-description='Maintain superior protection with our Safety Face Shield'>Buy Now - 25 Pack</Button>
        <LinkButton href='https://app.smartsheet.com/b/form/7a1533e8f76c4a45b3c8afdad250d316'>Request Bulk Pricing</LinkButton>
        <Button
          className='snipcart-add-item'
          data-item-id='2'
          data-item-name='Personal Protective Equipment (PPE) Donation'
          data-item-price='25.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-shippable='false'
          data-item-description='In addition to the donations we offer under our Sell Five, Donate One initiative, D6 supports requests from many US-based organizations/communities in need for bulk PPE donations. We are accepting monetary donations that will assist us in expanding our PPE donation program for these groups, which include (but are not limited to): first responders, Native American reservations, hospitals, essential workers, and homeless services organizations.'>Donate PPE</Button>
      </React.Fragment>
    )
  }

  rxReplacementActionButtons() {
    return (
      <React.Fragment>
        <Button
          className='snipcart-add-item'
          data-item-id='4'
          data-item-name='RX Safety Shield Replacement Shield - 10 Pack'
          data-item-price='10.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='545'
          data-item-description='The RX Replacement Shield is made from 100% recycled PET with an anti-fog coating. The Replacement shield can be easily removed and sanitized for multiple uses.'>Buy Now - 10 Pack</Button>
          <Button
          className='snipcart-add-item'
          data-item-id='5'
          data-item-name='RX Safety Shield Replacement Shield - 100 Pack'
          data-item-price='88.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='5444'
          data-item-description='The RX Replacement Shield is made from 100% recycled PET with an anti-fog coating. The Replacement shield can be easily removed and sanitized for multiple uses.'>Buy Now - 100 Pack</Button>
        {/* <Button
          className='snipcart-add-item'
          data-item-id='6'
          data-item-name='RX Safety Shield Replacement Shield - Donation'
          data-item-price='50.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-shippable='false'
          data-item-description='We are taking donations for face shields that will be sent to Native American Reservations, Homeless services, Grocery workers, Hospitals, and other first responders.'>Donate Replacement Shields</Button> */}
        <LinkButton href='https://app.smartsheet.com/b/form/7a1533e8f76c4a45b3c8afdad250d316'>Request Bulk Pricing</LinkButton>
      </React.Fragment>
    )
  }

  filterMaskActionButtons() {
    return (
      <React.Fragment>
        <Button
          className='snipcart-add-item'
          data-item-id='7'
          data-item-name='D6 Inc. USA-Made Particulate Respirator/Safety Face Mask'
          data-item-price='3.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='100'
          data-item-description='Maintain superior protection with our USA-Made Particulate Respirator/Safety Face Mask'>Buy Now</Button>
        <Button
          className='snipcart-add-item'
          data-item-id='8'
          data-item-name='D6 Inc. USA-Made Particulate Respirator/Safety Face Mask - 5 Pack'
          data-item-price='18.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='100'
          data-item-description='Maintain superior protection with our USA-Made Particulate Respirator/Safety Face Mask'>Buy Now - 5 Pack</Button>
        <Button
          className='snipcart-add-item'
          data-item-id='9'
          data-item-name='D6 Inc. USA-Made Particulate Respirator/Safety Face Mask - 10 Pack'
          data-item-price='24.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='100'
          data-item-description='Maintain superior protection with our USA-Made Particulate Respirator/Safety Face Mask'>Buy Now - 10 Pack</Button>
        <Button
          className='snipcart-add-item'
          data-item-id='10'
          data-item-name='D6 Inc. USA-Made Particulate Respirator/Safety Face Mask - 25 Pack'
          data-item-price='45.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='120'
          data-item-description='Maintain superior protection with our USA-Made Particulate Respirator/Safety Face Mask'>Buy Now - 25 Pack</Button>
        <Button
          className='snipcart-add-item'
          data-item-id='11'
          data-item-name='D6 Inc. USA-Made Particulate Respirator/Safety Face Mask - 50 Pack'
          data-item-price='86.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-weight='227'
          data-item-description='Maintain superior protection with our USA-Made Particulate Respirator/Safety Face Mask'>Buy Now - 50 Pack</Button>
          <LinkButton href='https://app.smartsheet.com/b/form/7a1533e8f76c4a45b3c8afdad250d316'>Request Bulk Pricing</LinkButton>
          <Button
          className='snipcart-add-item'
          data-item-id='12'
          data-item-name='Personal Protective Equipment (PPE) Donation'
          data-item-price='25.00'
          data-item-url='https://d6inc.com/covid-sales/'
          data-item-shippable='false'
          data-item-description='In addition to the donations we offer under our Sell Five, Donate One initiative, D6 supports requests from many US-based organizations/communities in need for bulk PPE donations. We are accepting monetary donations that will assist us in expanding our PPE donation program for these groups, which include (but are not limited to): first responders, Native American reservations, hospitals, essential workers, and homeless services organizations.'>Donate PPE</Button>
      </React.Fragment>
    )
  }

  actionInquire() {
    return (
      <LinkButton className='inquire' href='https://app.smartsheet.com/b/form/7a1533e8f76c4a45b3c8afdad250d316'>Inquire about availability*</LinkButton>
    )
  }

  usaMade() {
    return (
      <div className='usa-made'><img src={madeInUSA} /></div>
    )
  }

  covidSalesEmail() {
    return (
      <h2 className='email'>General questions can be directed to <a href='mailto:covidsales@d6inc.com'>covidsales@d6inc.com</a></h2>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="Buy Now" />
        <Products>

          <div className='product dg-filter-mask'>

            <div className='title-section'>
              <h1>D6 Inc. USA-Made Particulate Respirator/Safety Face Mask</h1>
              <h2>A state-of-the-art, American-made filtering facepiece respirator (FFR) offering ≥95%+ filtration efficiency against airborne particles and toxins</h2>
            </div>

            {this.donationHeader()}

            <div className='listing'>
              <div className='image'>
                <img src={d6FilterMask}/>
              </div>

              <div className='info'>
                <div className='actions'>
                  <span className='price'>$3.00</span>
                  {this.filterMaskActionButtons()}
                </div>

                <div className='details'>
                  <span>Item no: 6577</span>
                </div>
              </div>
            </div>

            <div className='specs'>
              <div className='column'>
                <span className='title'>Specifications:</span>
                <ul>
                  <li>5 pk: 8” x 5” x .5” / 1.16 oz.</li>
                  <li>10 pk: 8” x 5” x 1” / 1.91 oz.</li>
                  <li>25 pk: 6” x 5” x 2.5” / 4.24 oz.</li>
                  <li>50 pk: 6” x 10” x 3” / 8.02 oz.</li>
                </ul>
              </div>

              <div className='column'>
                <span className='title'>Benefits:</span>
                <ul className='bullet'>
                  <li><b>BREATHE EASIER:</b> Custom filtration material for maximum airflow and respiration</li>
                  <li><b>WORLD-CLASS COMFORT:</b> Lightweight, flexible design with center vertical seam provides ample space for nose and mouth, plus soft-touch elastic straps</li>
                  <li><b>FIVE LAYERS FOR ADDED PROTECTION:</b> five filtration layers provide ≥95% filtration efficiency, shielding against airborne particles and toxins (those .3 microns in diameter and larger)</li>
                </ul>
              </div>

              <div className='column'>
                <span className='title'>Details:</span>
                <ul className='bullet'>
                  <li>Malleable nosepiece</li>
                  <li>Foldable</li>
                  <li>Latex-free</li>
                  <li>Disposable</li>
                  <li>Can be worn with glasses and protective eyewear</li>
                  <li>One size fits most</li>
                  <li>Proudly designed and manufactured in Portland, Oregon</li>
                </ul>
              </div>
            </div>

            {this.covidSalesEmail()}
            {this.usaMade()}
          </div>

          <div className='product rx-face-shield'>

            <div className='title-section'>
              <h1>RX Safety Face Shield</h1>
              <h2>Maintain superior protection with our Safety Face Shield</h2>
            </div>

            <div className='listing'>
              <div className='image'>
                <img src={rxFaceShield}/>
              </div>

              <div className='info'>
                <div className='actions'>
                  <span className='price'>$12.00</span>
                  {this.rxFaceShieldActionButtons()}
                </div>

                <div className='details'>
                  <span>Item no: 6546RX</span>
                  <span>FDA Listing #D390949</span>
                </div>
              </div>
            </div>

            <div className='specs'>

              <div className='column'>
                <span className='title'>Specifications:</span>
                <ul>
                  <li>Size:  8.5” x 13"  </li>
                  <li>Thickness:  16-22 mm </li>
                  <li>Weight:  75 g </li>
                  <li>Packaging: 25-pc case pack </li>
                  <li>Packaging Dimensions: 24” x 20“ x 10”</li>
                  <li>Net Weight: 6 lbs.</li>
                  <br></br>
                  <li>Developed for medical use in response to COVID-19</li>
                  <br></br>
                  <li>Color of elastic band and headpiece subject to change</li>
                </ul>
              </div>

              <div className='column'>
                <span className='title'>Made from:</span>
                <ul className='bullet'>
                  <li>Lightweight, food-grade PET</li>
                  <li>Food-grade, silicone anti-fog coating</li>
                  <li>Optically clear, distortion free viewing</li>
                  <li>Secure fitting, adjustable elastic band</li>
                  <li>Padded forehead strip for all-day comfort</li>
                  <li>Fits well over eyeglasses, protective eye wear, N95 Masks, facial hair </li>
                  <li>Clean/sterilize with ethanol or isopropanol cleaning solutions</li>
                  <li>Built to FDA/CDC Specifications with FDA/CDC approved Materials</li>
                  <li>Part of OSHA standard 1910.1030 (blood borne pathogens) workplace compliance</li>
                  <li>CDC/NIOSH recommendation for protective eyewear</li>
                </ul>
              </div>

              <div className='column'>
                <span className='title'>Replacement Face Shields:</span>
                <ul>
                  <li>Item No: 6546SHR</li>
                  <li>Light weight</li>
                  <li>Convenient to replace</li>
                  <li>Instructions included with every box</li>
                  <li>Packaging: 100-pc Case Pack</li>
                  <li>Shipper Dimensions: 20” X 10” X 2”</li>
                  <li>Net Weight: 12 lb.</li>
                </ul>
              </div>
            </div>

            <div className='bottom-actions'>
              {this.rxFaceShieldActionButtons()}
            </div>

            {this.covidSalesEmail()}

            <div className='additional-images'>

              <div className='column'>
                <div className='image' style={{backgroundImage: `url(${head})`}}></div>
                <div className='info'>
                  <ul> 
                    <li>Face Shield Length</li>
                    <li>(Fully Assembled)</li>
                  </ul>
                </div>
              </div>

              <div className='column'>
                <div className='image' style={{backgroundImage: `url(${box})`}}></div>
                <div className='info'>
                  <ul> 
                    <li>Replacement Shields Item No: 6546SHR</li>
                    <li>Convenient to replace</li>
                    <li>Instructions included with every box</li>
                    <li>Available in 20” X 10” X 2” box</li>
                    <li>Net weight: 12 lb</li>
                    <li>100 per pack</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {this.usaMade()}
          
          <div className='product rx-face-shield-replacement'>

            <div className='title-section'>
              <h1>RX Safety Shield Replacement Shield</h1>
              <h2>Easily removed and sanitized for multiple uses. Made from 100% recycled PET with an anti-fog coating.</h2>
            </div>

            <div className='listing'>
              <div className='image'>
                <img src={rxFaceShieldReplacement}/>
              </div>

              <div className='info'>
                <div className='actions'>
                  <span className='price'>$10.00</span>
                  {this.rxReplacementActionButtons()}
                </div>

                <div className='details'>
                  <span>Item no: 6546SHR</span>
                </div>
              </div>
            </div>

            {this.covidSalesEmail()}
            {this.usaMade()}
          </div>

          <div className='product dg-n-95-filter-mask'>

            <div className='title-section'>
              <h1>D6 N-95 Filter Mask</h1>
              <h2>Maintain superior protection with our D6 Inc. Filter Mask</h2>
            </div>

            <div className='listing'>
              <div className='image'>
                <img src={d6FilterMaskCoomingSoon}/>
              </div>

              <div className='info'>
                <div className='actions'>
                  <span className='price'>$...</span>
                  {this.actionInquire()}
                </div>
              </div>
            </div>

            <div className='specs'>
              <h2 className='callout'>*Individuals and organizations that purchase the Safety Face Shield will be given priority when Filter Masks are available.</h2>
            </div>

            {this.covidSalesEmail()}
            {this.usaMade()}
          </div>
        </Products>
      </Layout>
    );
  } 
}
